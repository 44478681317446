
import { defineComponent, onMounted, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useSeos, useDeleteSeo } from '@/composables/api';
import { SeoIdOptions } from '@/services/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    SearchBox,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const sortBy = ref();
    const descSortBy = ref('updated_at');

    const { data, isLoading, isFetching, refetch } = useSeos({ page, keyword, sortBy, descSortBy });
    const route = useRoute();
    const { isLoading: isDeletedLoading, mutate } = useDeleteSeo();
    const statusIndex = ref(undefined);
    const { t } = useI18n();

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const deleteSeo = ({ seoId }: SeoIdOptions, index: Number) => {
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          statusIndex.value = index;
          mutate(
            { seoId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    return {
      page,
      data,
      isLoading,
      isFetching,
      isDeletedLoading,
      sortChange,
      deleteSeo,
      statusIndex,
      searchKeyword,
      t
    };
  }
});
